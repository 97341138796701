@tailwind base;
@tailwind components;
@tailwind utilities;

#nav-logo {
    font-family: 'Dancing Script', cursive;
    font-family: 'Playfair Display', serif;

}

#hero {
    background-image: url("/public/images/hero3.jpg");
    min-height: 500px;
    animation: fadeIn 2s ease-in-out;
}

#hero-text, #serv-page {
    animation: fadeIn 2s ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}




@media (max-width: 1100px) {
    #hero {
        background-image: url("/public/images/hero3.2.jpg");
        min-height: 500px;
    }
}

@media (max-width: 600px) {
    #serv-cards {
        display: grid;
        grid-template-columns: 1fr;
        padding: 1em;
        grid-row-gap: 25px;
    }

    #mani {
        width: 350px;
    }

    #pedi {
        width: 350px;
    }

    #art {
        width: 350px;
    }

    #add {
        width: 350px;
    }

    #wax {
        width: 350px;
    }
}

#services-hero {
    background-image: url("/public/images/hero1.jpg");
    min-height: 300px;
}

#techs-hero {
    background-image: url("/public/images/hero2.jpg");
    min-height: 500px;
}

#hero-title {
    font-family: 'Dancing Script', cursive;
    font-family: 'Playfair Display', serif;
}


.footer {
    left: 0;
    bottom: 0;
    width: 100%;
}

#footer-map {
    border: 0;
}

@media (max-width: 1100px) {
    #footer-map {
        display: none;
    }
}

#serv-container {
    position: relative;
}

#serv-img {
    border-radius: 50px;
    display: block;
    width: 100%;
    height: auto;
}

#serv-overlay {
    border-radius: 50px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
    background: rgba(0, 0, 0, 0.5);
}

#serv-container:hover #serv-overlay {
    height: 100%;
}

#serv-text {
    color: white;
    padding: 50px;
}

@media (max-width: 2600px) {
    #serv-text {
        font-size: xx-large;
    }
}

@media (max-width: 1800px) {
    #serv-text {
        font-size: large;
    }
}


@media (max-width: 1250px) {
    #serv-text {
        padding-top: 10px;
        font-size: small;
    }
}

@media (max-width: 950px) {
    #serv-text {
        display: none;
    }
}

@media (max-width: 700px) {
    #spacer {
        display: none;
    }
}


#review-card:hover {
    z-index: 1;
    transform: translateY(-10px) scale(1.05);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.2);
}

#review-card:hover~#review-card {
    opacity: 0.5;
}

#review-card {
    transition: opacity 0.2s ease-in-out;
}

#review-card:not(:hover) {
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
}


@media (max-width: 900px) {
    #home-about-img {
        width: 250px;
    }

    #home-about-title {
        text-align: center;
    }
}

@media (max-width: 800px) {
    #home-gallary {
        height: 600px;
    }
}


html {
    scroll-behavior: smooth;
}

#serv-title {
    text-align: center;
}

@media (max-width: 500px) {
    #serv-title {
        font-size: x-large;
    }
}


#serv_card_img {
    border-radius: 100px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}


/* Modal */
#overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
}

.modalContainer {
    max-width: 800px;
    width: 100%;
    position: fixed;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    border-radius: 20px;
}

#mod_img {
    width: 380px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.modalRight {
    width: 100%;
}

#closeBtn {
    position: fixed;
    top: 8px;
    right: 8px;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 3rem;
    padding: 1rem 2rem;
}

@media (max-width: 850px) {
    #mod_img {
        width: 250px;
        object-fit: scale-down;
    }

    .modalContainer {
        max-width: 650px;
    }
}

@media (max-width: 700px) {
    #mod_img {
        display: none;
    }

    .modalContainer {
        max-width: 400px;
    }
}

@media (max-width: 450px) {
    #mod_img {
        display: none;
    }

    .modalContainer {
        max-width: 300px;
    }

    #modal-form {
        width: 99%;
    }
}

.carousel {
    max-height: 800px;
    border-radius: 25px;
}